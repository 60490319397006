<template>
  <v-app>
    <v-main class="error">
      <v-container fill-height>
        <v-layout align-center justify-center>
          <v-flex>
            <v-row justify="center">
              <v-col cols="12" sm="10" md="8" lg="6" xl="4">
                <v-card class="px-10" tile>
                  <v-card-text class="text-left py-10 px-15">
                    <div class="d-flex justify-center mt-15"></div>

                    <div class="text-h2 error--text my-10 text-center">
                      ล็อคอินเข้าสู่ระบบ
                    </div>

                    <v-text-field
                      class="mb-0"
                      placeholder="ชื่อผู้ใช้งาน"
                      background-color="white"
                      outlined
                      dense
                      prepend-inner-icon="mdi-account"
                      name="username"
                      v-model="form.username"
                      :error-messages="
                        $v.form.username.$error ? $error_text : ''
                      "
                    >
                    </v-text-field>

                    <v-text-field
                      class="mb-0"
                      placeholder="รหัสผ่าน"
                      background-color="white"
                      outlined
                      dense
                      prepend-inner-icon="mdi-lock-open"
                      type="password"
                      name="password"
                      v-model="form.password"
                      :error-messages="
                        $v.form.password.$error ? $error_text : ''
                      "
                    >
                    </v-text-field>

                    <!-- <v-layout justify-space-between>
                      <v-checkbox class="mt-0" label="Remember me" color="app_primary"></v-checkbox>
                    </v-layout> -->

                    <v-btn
                      color="error"
                      elevation="0"
                      rounded
                      block
                      @click="login()"
                      :disabled="loading"
                      :loading="loading"
                    >
                      เข้าสู่ระบบ
                    </v-btn>

                    <span class="red--text" v-if="loginError">{{
                      loginError
                    }}</span>

                    <v-divider />

                    <div class="text-right mt-2 grey--text">
                     03/08/67 remove log store token
                    </div>
                  </v-card-text>

                  <!-- <v-card-text v-if="loginError" class="app_warning">
                    <div class="text-center red--text">Incorrect email or password.</div>
                  </v-card-text> -->
                </v-card>
              </v-col>
            </v-row>

            <div class="text-center font-weight-light white--text mt-10">
              2020 YAKYAI SOFT .CO.LTD . ALL RIGHT RESERVE
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'Login',

  data: () => ({
    loginError: false,
    loading: false,

    form: {
      username: '',
      password: ''
    }
  }),

  validations: {
    form: {
      username: { required },
      password: { required }
    }
  },
  async created() {
    if (localStorage.getItem('bo_access_token')) {
      console.log('remove bo_access_token in localStorage');
      localStorage.removeItem('bo_access_token');
    }
    if (localStorage.getItem('access_token')) {
      console.log('remove access_token in localStorage');
      localStorage.removeItem('access_token');
    }
  },
  methods: {
    async login() {
      this.loginError = '';
      this.$v.form.$touch();
      if (this.$v.form.$invalid) return;

      this.loading = true;

      let body = {
        token: this.$jwt.sign(this.form, this.$privateKey, {
          noTimestamp: true
        })
      };
      await this.$axios
        .post(`${this.$baseUrl}/backend/users/authen`, body)
        .then(async res => {
          localStorage.setItem('bo_access_token', `Bearer ${res.result}`);
          // this.$router.replace('/backoffice/backoffice/product');
          window.location.href = '/backoffice/customer';
        })
        .catch(err => {
          this.loginError = err.error_message;
          //login
        })
        .then(() => {
          this.loading = false;
        });
    }
  }
};
</script>
